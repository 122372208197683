@import "@/assets/css/prepend.scss";
@import './global.scss';
.sD{
  @include mob{
    display: none;
  }
}
.sM{
  display: none;
  @include mob{
    display:block;
  }
}
header{
  position: absolute; 
  left: 0; right: 0; top: $d40px;
  z-index: 98;
  height: $d42px;
  @include mob{
    top: $m20px;
    height: $m36px;
  }
  @include large{
    top: $l40px;
    height: $l42px;
  }
  .container{position: relative;}
  .raw{
    display: grid;
    grid-template-columns: $d400px 1fr;
    gap: 0;
    @include mob{
      grid-template-columns: $m200px 1fr;
    }
    @include large{
      grid-template-columns: $l400px 1fr;
    }
    .lCol{
      .logo{
        width: $d159px;
        height: $d42px;
        background-image: url(../images/logo.svg);
        background-position: top left;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        @include mob{
          width: $m137px;
          height: $m36px;
        }
        @include large{
          width: $l159px;
          height: $l42px;
        }
      }

    }
    .rCol{
      @include mob{
        display: flex;
        align-items: center;
        justify-content: flex-end; 
        position: relative;
      }
      .menu{
        height: $d42px;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: flex-end; 
        @include mob{
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          background: #EBFB72;
          border-radius: $m18px 0px $m18px $m18px;
          height: auto;
          flex-direction: column;
          padding: $m10px $m22px $m10px $m16px;
          z-index: 101;
        }
        @include large{
          height: $l42px;
        }
        li{
          display: flex;
          align-items: flex-end;
          height: $d42px;
          @include mob{
          height: $m30px;
          }
          @include large{
            height: $l42px;
          }
          &+li{
            margin-left: 1px;
            @include mob{
              margin-left: 0;
              border-top: 1px solid #CBDD3E;
            }
          }
          a{
            cursor: pointer;
            display: block;
            background: #F2F2F2;
            border-radius:  $d20px  $d20px 0 0;
            width: $d144px;
            height: $d42px;
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size: $d16px;
            line-height: $d23px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #000000;
            &:hover{
              text-decoration: none;
              height: $d31px;
              background: #EBFB72;
              @include mob{
                background:none;
              }
            }
            &.selected{
              background: #EBFB72;
              @include mob{
                background:none;
              }
            }
            @include mob{
              background: none;
              width: $m126px;
              height: $m30px;
              font-size: $m16px;
              line-height: $m30px;
              justify-content: flex-start;
              &:hover{
                height: $m30px;
                background: none;
              }

            }
            @include large{
              border-radius:  $l20px  $l20px 0 0;
              width: $l144px;
              height: $l42px;
              font-size: $l16px;
              line-height: $l23px;
              &:hover{
                height: $l31px;
              }

            }
            
          }
        }
        &.showMenu{
          @include mob{
            display: flex;
          }
        }
      }
      .menuOpen{
        display: none;
        @include mob{
          display: flex;
        }
        align-items: flex-end;
        height: $m36px;
        a{
          display: block;
          background: #F2F2F2;
          border-radius: $m18px $m18px 0 0;
          width: $m80px;
          height: $m36px;
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $m15px;
          line-height: $m21px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #000000;
        }
        &.showMenu{
          position: relative;
          z-index: 100;
          a{
            background: #EBFB72;
          }
        }
      }
      .menuShadow{
        position: fixed;
        left: 0; right: 0; top: 0; bottom: 0;
        z-index: 99;
        display: none;
        &.showMenu{
          display: block;
        }
      }
    }
  }
}
.container{
    width: 100%;
    margin-right:auto;
    margin-left:auto;
    max-width:$d1368px;
    @include mob{
      max-width:$m340px;
    }
    @include large{
      max-width:$l1780px;
    }
}
section.home{
  position: relative;
  height: $d750px;
  background: #CBB8F8;
  background-image: url(../images/laikabg.svg);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0px 0px $d50px $d50px;
  @include mob{
    height: $m697px;
    border-radius:0;
    background-image: url(../images/laikamobile.svg);
    background-position: bottom center;
  }
  @include large{
    height: $l1086px;
    border-radius: 0px 0px $l50px $l50px;
  }
  .title{
    position: absolute;
    top:$d334px;
    left: 0;
    font-family: 'Steinbeck';
    font-style: normal;
    font-weight: 400;
    height: $d122px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #000000;
    img{
      height: $d122px;
      @include mob{
        height: $m76px;
      }
      @include large{
        height: $l160px;
      }
    }
    @include mob{
      top:$m130px;
      height: $m76px;
    }
    @include large{
      top:$l482px;
      height: $l160px;
    }
  }
  .eyes{
    position: absolute;
    top: $d316px;
    left: 50%;
    @include mob{
      top: auto;
      bottom: $m224px;
    }
    @include large{
      top: $l458px;
    }
    .eyeL,.eyeR{
      position: absolute;
      width: $d198px; 
      height: $d154px;
      @include mob{
        width: $m124px; 
        height: $m96px;
      }
      border-radius: 10.41666666667vw/8.33333333333vw;
      @include mob{
        border-radius: 150px/120px;
      }
      @include large{
        border-radius: 7.8125vw/6.25vw;
      }
      background: #FFFFFF;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .eyeSocket{
        position: relative;
        width: $d198px; 
        height: $d198px;
        opacity: 1;
        @include transition(all .3s ease-in-out);
        @include mob{
          width: $m124px; 
          height: $m124px;
        }
        .eyeball{
          position: relative;
          border-radius: 100%;
          background: #FFFFFF;
          width: $d198px; 
          height: $d198px;
          @include mob{
            width: $m124px; 
            height: $m124px;
            transform: rotate(0deg)!important;
          }
          .pupil{
            position: absolute;
            top: $d34px;
            right: $d9px;
            width: $d130px; 
            height: $d130px;
            background: #000000;
            border-radius: 100%;
            @include mob{
              top: -$m18px!important;
              right: $m18px!important;
              width: $m81px; 
              height: $m81px;
              transform: rotate(0deg)!important;
            }
            .shine{
              position: absolute;
              top:$d50px;
              right: $d16px;
              width: $d30px; 
              height: $d30px;
              @include mob{
                width: $m18px; 
                height: $m18px;
                top:$m31px;
                right: $m10px;
              }
              background: #FFFFFF;
              border-radius: 100%;
              
            }
          }
        }
      }
      .likeSocket{
        position: absolute;
        width: $d198px; 
        height: $d198px;
        @include mob{
          width: $m124px; 
          height: $m124px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        .like{
          width: $d116px;
          height: $d96px;
          @include mob{
            width: $m88px; 
            height: $m75px;
          }
          background-image: url(../images/like.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          @include transform(scale(0.01));
          opacity: 0;
          @include transition(all .3s ease-in-out);
          &.puls{
            animation: liking .5s infinite linear;
          }
        }
      }
      
    }
    .eyeL{
      right: $d39px;
      @include mob{
        right: $m24px;
      }
      @include large{
        right: $l59px;
      }
      @include transform_rotate(12.37deg);
      .eyeSocket,.likeSocket{
        @include transform_rotate(-12.37deg);
      }
    }
    .eyeR{
      left: $d51px;
      @include mob{
        left: $m24px;
      }
      @include large{
        left: $l81px;
      }
      @include transform_rotate(-12.37deg);
      .eyeSocket,.likeSocket{
        @include transform_rotate(12.37deg);
      }
    }
    &.liked{
      .eyeL,.eyeR{
        .eyeSocket{
          opacity: 0;}
        .like{
          @include transform(scale(1));
          opacity: 1;
        }

      }
    }
  }
  .hButton{
    position: fixed;
    z-index:1000;
    right: $d38px;
    bottom: $d44px;
    width: $d114px;
    height: $d113px;
    background-image: url(../images/hButton.svg);
    background-position: top left;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity:1;
    @include transition(all .3s ease-in-out);
    animation: jitter 3s infinite linear;
    @include mob{
      position: absolute;
      right: auto;
      bottom: auto;
      left: $m106px; top:$m242px;
      width: $m162px;
      height: $m43px;
      background-image: url(../images/hButton_mobile.svg);
    }
    &:hover{
      @include desk{
        background-image: url(../images/hButton_hover.svg);
      }
      @include large{
        background-image: url(../images/hButton_hover.svg);
      }
    }
    &.hide{
      @include desk{
        opacity:0;
        pointer-events: none;
      }
      @include large{
        opacity:0;
        pointer-events: none;
      }
    }
    @include large{
      right: $l45px;
      bottom: $l50px;
      width: $l134px;
      height: $l132px;
    }
  }

}

@keyframes jitter {
  0% {
    @include transform_rotate(0deg);
  }
  40% {
    @include transform_rotate(0deg);
  }
  45% {
    @include transform_rotate(12deg);
  }
  50% {
    @include transform_rotate(-14deg);
  }
  57% {
    @include transform_rotate(10deg);
  }
  63% {
    @include transform_rotate(-4deg);
  }
  68% {
    @include transform_rotate(0deg);
  }
  100% {
    @include transform_rotate(0deg);
  }
}

@keyframes liking {
  0% {
    @include transform(scale(1));
  }
  45% {
    @include transform(scale(1.1));
  }
  90% {
    @include transform(scale(0.9));
  }
  100% {
    @include transform(scale(1));
  }
}

@-webkit-keyframes scroll {
  0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
  }
  100% {
      -webkit-transform: translate(-100%, 0);
      transform: translate(-100%, 0)
  }
}

@-moz-keyframes scroll {
  0% {
      -moz-transform: translate(0, 0);
      transform: translate(0, 0);
  }
  100% {
      -moz-transform: translate(-100%, 0);
      transform: translate(-100%, 0)
  }
}

@keyframes scroll {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-100%, 0)
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.marquee span {
  display: inline-block;
  -webkit-animation: scroll 35s infinite linear;
  -moz-animation: scroll 35s infinite linear;
  animation: scroll 35s infinite linear;
  padding-left: 100%;
}
section.homeDisc{
  padding-top: $d90px;
  padding-bottom: $d80px;
  @include mob{
    padding-top: $m55px;
    padding-bottom: $m60px;
  }
  @include large{
    padding-top: $l118px;
    padding-bottom: $l80px;
  }
  .text{
    font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 400;
    font-size: $d48px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #000000;
    @include mob{
      font-size: $m35px;
      line-height: 131%;
    }
    @include large{
      font-size: $l60px;
    }
    
  }
}
.projects{
  margin-bottom: $d59px;
  @include mob{
    margin-bottom: $m57px;
  }
  @include large{
    margin-bottom: $l90px;
  }
  .mpTitle{
    font-family: 'Steinbeck';
    font-style: normal;
    font-weight: 400;
    font-size: $d24px;
    line-height: $d24px;
    text-transform: uppercase;
    color: #000000;
    @include mob{
      font-size: $m20px;
      line-height: $m20px;
    }
    margin-bottom: $d30px;
    @include mob{
      margin-bottom: $m15px;
    }
    @include large{
      font-size: $l30px;
      line-height: $l30px;
      margin-bottom: $l40px;
    }

  }
  .tags{
    margin-bottom: $d60px;
    @include mob{
      margin-bottom: $m40px;
    }
    @include large{
      margin-bottom: $l69px;
    }
    &>.tagsList{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      gap: $d15px;
      @include mob{
        gap: $m10px;
      }
      @include large{
        gap: $l15px;
      }
      &>li{
        display: block;
        a{
          display: flex;
          height: $d53px;
          border: 1px solid #000000;
          border-radius: $d100px;
          padding: 0 $d50px;
          white-space: nowrap;
          align-items: center;
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d16px;
          line-height: $d23px;
          display: flex;
          color: #000000;
          cursor: pointer;
          text-decoration: none;
          &.selected{
            color: #FFFFFF;
            background: #000000;
          }
          @include mob{
            height: $m41px;
            border-radius: $m50px;
            padding: 0 $m30px;
            font-size: $m15px;
            line-height: $m21px;
          }
          @include large{
            height: $l60px;
            border-radius: $l32px;
            padding: $l20px $l60px;
            font-size: $l20px;
            line-height: 100%;
          }
        }
      }
    }
  }
  .cards{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $d19px;
    @include mob{
      grid-template-columns: 1fr;
      gap: $m17px;
    }
    @include large{
      gap: $l30px;
    }
    .card{
      display: block;
      cursor: pointer;
      height: $d440px;
      overflow: hidden;
      position: relative;
      border-radius: $d25px;
      @include mob{
        height: $m222px;
        border-radius: $m15px;
      }
      @include large{
        height: $l570px;
        border-radius: $l25px;
      }
      .title{
        position: absolute;
        left: $d34px; bottom: $d21px;
        width: $d313px;
        font-family: 'Steinbeck';
        font-style: normal;
        font-weight: 400;
        font-size: $d32px;
        line-height: 107%;
        text-transform: uppercase;
        color: #FFFFFF;
        @include mob{
          left: $m18px; bottom: $m12px;
          width: $m196px;
          font-size: $m20px;
        }
        @include large{
          left: $l43px; bottom: $l34px;
          width: $l411px;
          font-size: $l42px;
        }
      }
      .ptags{
        position: absolute;
        left: $d36px; top: $d30px;
        display: flex;
        gap: $d10px;
        @include mob{
          left: $m17px; top: $m17px;
          gap: $m5px;
        }
        @include large{
          left: $l45px; top: $l45px;
          gap: $l10px;
        }
        .ptag{
          height: $d37px;
          border: 1px solid #FFFFFF;
          border-radius: $d32px;
          padding: $d10px $d30px;
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d12px;
          line-height: $d17px;
          text-transform: capitalize;
          color: #FFFFFF;
          @include mob{
            height: $m27px;
            border-radius: $m32px;
            padding: $m7px $m20px;
            font-size: $m9px;
            line-height: $m13px;
          }
          @include large{
            height: $l45px;
            border-radius: $l32px;
            padding: $l12px $l40px;
            font-size: $l15px;
            line-height: $l21px;

          }

        }
      }
      .bg{
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
        .img{
          width: 100%;
          height: 100%;
          background-position: top left;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: $d25px;
          @include mob{
            border-radius: $m15px;
          }
          @include large{
            border-radius: $l25px;
          }
        }
        video{
          width: 100%;
          height: 100%;
          border-radius: $d25px;
          @include mob{
            border-radius: $m15px;
          }
          @include large{
            border-radius: $l25px;
          }
        }
      }
      &.black{
        .title{
          color: #000000;
        }
        .ptags .ptag{
          border: 1px solid #000000;
          color: #000000;

        }
      }
    }
  }
}
section.clients{
  margin-top: $d90px;
  margin-bottom: $d80px;
  @include mob{
    margin-top: $m60px;
    margin-bottom: $m60px;
  }
  @include large{
    margin-top: $l100px;
    margin-bottom: $l100px;
  }
  .title{
    font-family: 'Steinbeck';
    font-style: normal;
    font-weight: 400;
    font-size: $d24px;
    line-height: $d24px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: $d30px;
    @include mob{
      font-size:  $m20px;
      line-height:  $m20px;
      margin-bottom: $m20px;

    }
    @include large{
      font-size:  $l30px;
      line-height:  $l30px;
      margin-bottom: $l40px;

    }

  }
  .items{
    display: grid;
    gap: $d21px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include mob{
      display: none;
    }
    @include large{
      gap: $l30px;
    }
    .groupItem{
      width: $d327px;
      height: $d205px;
      border: 1px solid #000000;
      border-radius: $d25px;
      position: relative;
      overflow: hidden;
      @include large{
        width: $l423px;
        height: $l265px;
        border-radius: $l32px;
      }
      .item{
        position: absolute;
        width: $d327px;
        height: $d205px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include large{
          width: $l423px;
          height: $l265px;
        }
        img{
          &.client_k31{
            width: $d75px;
            @include large{
              width: $l99px;
            }
          }
          &.client_loreal{
            width: $d124px;
            @include large{
              width: $l162px;
            }
          }
          &.client_mig{
            width: $d122px;
            @include large{
              width: $l122px;
            }
          }
          &.client_samsung{
            width: $d135px;
            @include large{
              width: $l177px;
            }
          }
          &.client_delimobil{
            width: $d171px;
            @include large{
              width: $l224px;
            }
          }
          &.client_nissan{
            width: $d95px;
            @include large{
              width: $l124px;
            }
          }
          &.client_ns{
            width: $d147px;
            @include large{
              width: $l185px;
            }
          }
          &.client_pepsico{
            width: $d142px;
            @include large{
              width: $l185px;
            }
          }
        }
        @include transition(all .5s ease-in-out);
      }
      &.state1{
        .item:nth-child(1){
          transform: rotateX(0deg);
          top:0;
          opacity: 1;
        }
        .item:nth-child(2){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        .item:nth-child(3){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        .item:nth-child(4){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        
      }
      &.state2{
        .item:nth-child(1){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(2){
          transform: rotateX(0deg);
          top:0;
          opacity: 1;
        }
        .item:nth-child(3){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        .item:nth-child(4){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        
      }
      &.state3{
        .item:nth-child(1){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(2){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(3){
          transform: rotateX(0deg);
          top:0;
          opacity: 1;
        }
        .item:nth-child(4){
          transform: rotateX(90deg);
          top:$d103px;
          opacity: 0.7;
        }
        
      }
      &.state4{
        .item:nth-child(1){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(2){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(3){
          transform: rotateX(90deg);
          top:-$d103px;
          opacity: 0.7;
        }
        .item:nth-child(4){
          transform: rotateX(0deg);
          top:0;
          opacity: 1;
        }
        
      }
    }
  }
  .itemsMob{
    display: none;
    gap: $d21px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include mob{
      gap: $m15px;
      grid-template-columns: 1fr 1fr;
      display: grid;
    }
    @include large{
      gap: $l30px;
    }
    .item{
      width: $d327px;
      height: $d205px;
      border-radius: $d25px;
      border: 1px solid #000000;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mob{
        width:  $m163px;
        height:  $m125px;
        border-radius:  $m15px;

      }
      @include large{
        width: $l423px;
        height: $l265px;
        border-radius: $l32px;
      }
      img{
        &.client_k31{
          width: $d75px;
          @include mob{
            width: $m38px;
          }
          @include large{
            width: $l99px;
          }
        }
        &.client_loreal{
          width: $d124px;
          @include mob{
            width: $m61px;
          }
          @include large{
            width: $l162px;
          }
        }
        &.client_mig{
          width: $d122px;
          @include mob{
            width: $m58px;
          }
          @include large{
            width: $l122px;
          }
        }
        &.client_samsung{
          width: $d135px;
          @include mob{
            width: $m73px
          }
          @include large{
            width: $l177px;
          }
        }
        &.client_delimobil{
          width: $d171px;
          @include mob{
            width: $m85px;
          }
          @include large{
            width: $l224px;
          }
        }
        &.client_nissan{
          width: $d95px;
          @include mob{
            width: $m50px;
          }
          @include large{
            width: $l124px;
          }
        }
        &.client_ns{
          width: $d147px;
          @include mob{
            width: $m70px;
          }
          @include large{
            width: $l185px;
          }
        }
        &.client_pepsico{
          width: $d142px;
          @include mob{
            width: $m76px;
          }
          @include large{
            width: $l185px;
          }
        }
      }
    }
  }
  &.clientsSimple{
    .items{
      display: none;
    }
    .itemsMob{
      display: grid;
    }
  }
}
footer{
  padding-bottom: $d44px;
  margin-top: $d60px;
  @include mob{
    padding-bottom: $m20px;
    margin-top: $m60px;
  }
  @include large{
    padding-bottom: $l60px;
    margin-top: $l100px;
  }
  .raw{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include mob{
      grid-template-columns: 1fr 1fr;
    }
    .cLogo{
      font-family: 'Atyp Display';
      font-style: normal;
      font-weight: 400;
      font-size: $d20px;
      line-height: 120%;
      color: #000000;
      @include mob{
        font-size: $m18px;
        line-height: $m25px;
      }
      @include large{
        font-size: $l26px;
        line-height: $l37px;
      }
    }
    .cMail{
      text-align: center;
      @include mob{
        text-align: right;
      }
      a{
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size:  $d20px;
        line-height:  $d28px;
        text-align: center;
        color: #000000;
        @include mob{
          font-size: $m18px;
          line-height: $m25px;
        }
        @include large{
          font-size: $l26px;
          line-height: $l37px;
        }
      }
    }
    .cContacts{
      display: flex;
      justify-content: flex-end;
      @include mob{
        display: none;
      }
      ul{
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        li{
          display: block;
          &+li{
            margin-left: $d29px;
            @include large{
              margin-left: $l34px;
            }
          }
          a{
            font-family: 'Atyp Display';
            font-style: normal;
            font-weight: 400;
            font-size:  $d20px;
            line-height:  $d28px;
            
            color: #000000;
            @include large{
              font-size: $l26px;
              line-height: $l37px;
            }

          }
        }

      }
    }
  }
}
.projectsPage{
  padding-top: $d150px;
  @include mob{
    padding-top: $m120px;
  }
  @include large{
    padding-top: $l200px;
  }
}

@keyframes rotateBtn {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contactsPage{
  padding-top: $d122px;
  margin-bottom: $d80px;
  @include mob{
    padding-top: $m120px;
    margin-bottom: $m60px;
  }
  @include large{
    padding-top: $l200px;
    margin-bottom: $l100px;
  }
  section.contacts{
    .container{
      position: relative;
    }
    .sun{
      position: absolute;
      top: 0; left: $d591px;
      width: $d291px;
      height: $d291px;
      background-image: url(../images/sun.svg);
      background-position: top left;
      background-size: 100%;
      background-repeat: no-repeat;
      animation: rotateBtn 3s infinite linear;
      @include mob{
        left: $m122px;
        width: $m212px;
        height: $m212px;
      }
      @include large{
        left: $l844px;
        width: $l369px;
        height: $l369px;
      }

    }
    .title{
      position: absolute;
      left: 0; top: $d89px;
      width: $d728px;
      height: $d231px;
      font-family: 'Atyp Display';
      font-style: normal;
      font-weight: 400;
      font-size: $d64px;
      line-height: 120%;
      color: #000000;
      @include mob{
        top: $m166px;
        width: 100%;
        height: $m129px;
        font-size: $m36px;
      }
      @include large{
        width: $l964px;
        height: $l300px;
        top: $l151px;
        font-size: $l87px;
      }

    }
    .details{
      margin-left: $d693px; padding-top: $d337px;
      @include mob{
        padding-top: $m369px;
        margin-left: 0;
      }
      @include large{
        margin-left: $l905px; padding-top: $l492px;
      }
      .detail_item{
        display: grid;
        grid-template-columns: $d120px 1fr;
        padding-bottom: 1px;
        border-bottom: 1px solid #000000;
        @include mob{
          grid-template-columns: $m120px 1fr;
        }
        @include large{
          grid-template-columns: $l150px 1fr;
        }
        .label{
          font-family: 'Steinbeck';
          font-style: normal;
          font-weight: 400;
          font-size: $d20px;
          line-height: 120%;
          text-transform: uppercase;
          color: #000000;
          @include mob{
            font-size: $m20px;
          }
          @include large{
            font-size: $l26px;
            line-height: $l26px;
          }
        }
        .value{
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d20px;
          line-height: $d28px;
          text-align: right;
          color: #000000;
          @include mob{
            font-size: $m18px;
            line-height: $m25px;
          }
          @include large{
            font-size: $l26px;
            line-height: $l37px;
          }
          a{
            color: #000000;
            &:hover{
              text-decoration: none;
            }
          }
        }
        &+.detail_item{
          margin-top: $d52px;
          @include mob{
            margin-top: $m43px;
          }
          @include large{
            margin-top: $l81px;
          }
        }
      }
    }

  }
}
.aboutPage{
  padding-top: $d175px;
  @include mob{
    padding-top: $m116px;
  }
  @include large{
    padding-top: $l225px;
  }
  

}
section.about{
  .title{
    font-family: 'Steinbeck';
    font-style: normal;
    font-weight: 400;
    font-size: $d24px;
    line-height: $d24px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: $d30px;
    margin-top: $d80px;
    @include mob{
      font-size:  $m20px;
      line-height:  $m20px;
      margin-bottom: $m20px;
      margin-top: $m60px;

    }
    @include large{
      font-size: $l30px;
      line-height: $l30px;
      margin-bottom: $l40px;
      margin-top: $l100px;

    }

  }
  .desc{
    font-family: 'Atyp Display';
    font-style: normal;
    font-weight: 400;
    font-size: $d48px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #000000;
    @include mob{
      font-size: $m34px;
      line-height: 131%;
    }
    @include large{
      font-size: $l60px;
      line-height: 150%;
    }
    b{
      font-weight: 500;
    }
  }
  .services{
    .items{
      display: flex;
      flex-wrap: wrap;
      gap: $d15px;
      @include mob{
        gap: $m10px;
      }
      @include large{
        gap: $l15px;
      }
      .item{
        display: flex;
        height: $d53px;
        border: 1px solid #000000;
        border-radius: $d100px;
        padding: 0 $d50px;
        white-space: nowrap;
        align-items: center;
        font-family: 'Atyp Display';
        font-style: normal;
        font-weight: 400;
        font-size: $d16px;
        line-height: $d23px;
        display: flex;
        color: #000000;
        text-decoration: none;
        @include mob{
          height: $m41px;
          border-radius: $m50px;
          padding: 0 $m30px;
          font-size: $m15px;
          line-height: $m21px;
        }
        @include large{
          height: $l60px;
          border-radius: $l90px;
          padding: 0 $l60px;
          font-size: $l20px;
          line-height: 100%;

        }
      }
    }
  }
  .team{
    .items{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr  1fr;
      gap: $d20px;
      row-gap: $d60px;
      @include mob{
        grid-template-columns: 1fr;
        gap: $m40px;
        row-gap: $m40px;
      }
      @include large{
        gap: $l30px;
        row-gap: $l90px;
      }
      .item{
        .img{
          width: $d327px;
          height: $d385px;
          // background-image: url(../images/hButton.svg);
          background-position: top left;
          background-size: 100%;
          background-repeat: no-repeat;
          border-radius: $d25px;
          @include mob{
            width: $m340px;
            height: $m400px;
            border-radius: $m15px;
          }
          @include large{
            width: $l423px;
            height: $l495px;
            border-radius: $l32px;
          }
        }
        .name{
          margin-top: $d17px;
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d20px;
          line-height: 120%;
          display: flex;
          align-items: center;
          color: #000000;
          margin-bottom: $d5px;
          @include mob{
            margin-top: $m15px;
            margin-bottom: $m5px;
            font-size: $m18px;
            line-height: $m25px;

          }
          @include large{
            margin-top: $l20px;
            margin-bottom: $l5px;
            font-size: $l26px;
            line-height: $l37px;
          }

        }
        .position{
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d16px;
          line-height: 140%;
          color: #333333;
          @include mob{
            font-size: $m15px;
          }
          @include large{
            font-size: $l20px;
          }
        }
      }
    }
  }
}
section.project{
  .projectHeader{
    .desk{
      display: block;
    }
    .mob{
      display: none;
    }
    @include mob{
      .desk{
        display: none;
      }
      .mob{
        display: block;
      }

    }
    img{
      width: 100%;
    }
    video{
      width: 100%;
    }
  }
  .projectMainData{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: $d80px;
    gap:$d20px;
    @include mob{
      margin-bottom: $m30px;
      grid-template-columns: 1fr;
      gap:$m20px;
    }
    @include large{
      margin-bottom: $l100px;
      gap:$l30px;
    }

    .cTitle{
      .title{
        margin-top: $d40px;
        margin-bottom: $d25px;
        font-family: 'Steinbeck';
        font-style: normal;
        font-weight: 400;
        font-size: $d55px;
        line-height: $d55px;
        text-transform: uppercase;
        color: #000000;
        @include mob{
          margin-top: $m40px;
          margin-bottom: $m20px;
          font-size: $m40px;
          line-height: $m40px;
        }
        @include large{
          margin-top: $l60px;
          margin-bottom: $l25px;
        }
      }
      .ptags{
        display: flex;
        gap: $d13px;
        @include mob{
          gap: $m10px;
        }
        @include large{
          gap: $l14px;
        }
        .ptag{
          height: $d42px;
          border: 1px solid #000000;
          border-radius: $d20px;
          padding: $d10px $d30px;
          font-family: 'Atyp Display';
          font-style: normal;
          font-weight: 400;
          font-size: $d16px;
          line-height: 140%;
          text-transform: capitalize;
          color: #000000;
          @include mob{
            height: $m31px;
            border-radius: $m20px;
            padding: $m5px $m20px;
            font-size: $m15px;
          }
          @include large{
            height: $l48px;
            border-radius: $l30px;
            padding: $l10px $l30px;
            font-size: $l20px;
          }
  
        }
      }
    }
    .cDesc{
      margin-top: $d40px;
      font-family: 'Atyp Display';
      font-style: normal;
      font-weight: 400;
      font-size: $d16px;
      line-height: 140%;
      color: #000000;
      @include mob{
        font-size: $m16px;
      }
      @include large{
        font-size: $l20px;
      }

    }
  }
  .content{
    display: flex;
    flex-direction: column;
    gap: $d20px;
    @include mob{
      gap: $m15px;
    }
    @include large{
      gap: $l30px;
    }
    .cItem{
      img{
        width: 100%;
        border-radius: $d25px;
        @include mob{
          border-radius: $m15px;
        }
        @include large{
          border-radius: $l32px;
        }
      }
      video{
        width: 100%;
        border-radius: $d25px;
        @include mob{
          border-radius: $m15px;
        }
        @include large{
          border-radius: $l32px;
        }
      }
      .raw{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $d20px;
        @include mob{
          grid-template-columns: 1fr;
          gap: $m15px;
        }
        @include large{
          gap: $l30px;
        }
      }
      .text{
        padding-top: $d30px;
        padding-bottom: $d60px;
        @include mob{
          padding-top: 0;
          padding-bottom: $m16px;
        }
        @include large{
          padding-top: $l30px;
          padding-bottom: $l60px;

        }
      }
      font-family: 'Atyp Display';
      font-style: normal;
      font-weight: 400;
      font-size: $d16px;
      line-height: 140%;
      color: #000000;
      @include mob{
        gap: $m15px;
        font-size: $m15px;
      }
      @include large{
        font-size: $l20px;

      }
    }
  }
  .team{
    margin-top: $d40px;
    margin-bottom: $d80px;
    @include mob{
      margin-top: $m30px;
      margin-bottom: $m60px;
    }
    @include large{
      margin-top: $l60px;
      margin-bottom: $l100px;
    }
    .raw{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $d20px;
      @include mob{
        grid-template-columns: 1fr;
        gap: $m15px;
      }
      @include large{
        gap: $l30px;
      }
    }
    .title{
      margin-bottom: $d32px;
      font-family: 'Steinbeck';
      font-style: normal;
      font-weight: 400;
      font-size: $d24px;
      line-height: $d24px;
      text-transform: uppercase;
      color: #000000;
      @include mob{
        margin-bottom: $m15px;
        font-size: $m20px;
        line-height: $m20px;
      }
      @include large{
        margin-bottom: $l40px;
        font-size: $l30px;
        line-height: $l30px;
      }
    }
    .text{
      font-family: 'Atyp Display';
      font-style: normal;
      font-weight: 400;
      font-size: $d16px;
      line-height: $d23px;
      color: #000000;
      @include mob{
        font-size:  $m15px;
        line-height:  $m21px;
      }
      @include large{

        font-size: $l20px;
        line-height: $l28px;
      }
      

    }
  }
}