@import "@/assets/css/prepend.scss";
@import './global.scss';
@mixin transform_rotate($val) {
	-webkit-transform: rotate($val);
	-moz-transform: rotate($val);
	-o-transform: rotate($val);
	transform: rotate($val);
}

@mixin transition($val) {
    -webkit-transition: $val;
    -moz-transition: $val;
    -o-transition: $val;
    transition: $val;
}
body,html{
    min-height: 100vh;
}
html{
    background: #FFFFFF;
} 
body{
	direction: ltr;
	background: #FFFFFF;
    color: #333333;
    overflow-x: hidden;
    font-family: 'Arial',sans-serif;
    font-style: normal;
    font-weight: 400;
}
section{
	display:block;
}


.selectorInput{
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    input[type=text]{
        margin-bottom: 0px;

    }
    .toggleSelectorIndicator{
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 12px;
        &::before{
            display: block;
            content: '';
            // background-image: url('../images/v-down.svg');
            background-repeat: no-repeat;
            background-position: center center;
            @include transition(all .3s ease-in-out);
            width: 12px;
            height: 100%;
        }
    }
    input[type=text]:focus+.toggleSelectorIndicator{
        &::before{
            @include transform_rotate(180deg);
        }
    }
    .clearSelector{
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;
        width: 22px;
        display: none;
        &::before{
            display: block;
            content: '';
            // background-image: url('../images/clear.svg');
            background-repeat: no-repeat;
            background-position: center center;width: 22px;height: 100%;
        }
    }
    
    input[type=text]:focus+.toggleSelectorIndicator+.selector+.clearSelector{
        display: block;
    }
    .selector{
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        background: #FFFFFF;
        max-height: 400px;
        overflow: auto;
        display: none;
        z-index: 101;
        &::-webkit-scrollbar{height:15px;width:15px; background-color: transparent; }
        &::-webkit-scrollbar-button{height: 3px;width:0}
        &::-webkit-scrollbar-thumb{
            background-clip:padding-box;
            background: #E5E5E5;
            border-radius: 6px;
            border:none;
            border-right: 4px solid #FFFFFF;
            border-left: 4px solid #FFFFFF;
        }
        &::-webkit-scrollbar-track{
            background-color:transparent;
        }
        a{
            display: block;
            height: 40px;
            font-size: 16px;
            line-height: 40px;
            color: #020202;
            text-decoration: none;
            outline: 0;
            text-align: left;
            padding-left: 17px;
            &.other{
                background: #EFEFEF;
                cursor: pointer;
                &:hover{
                    background: #EFEFEF;
                    color: #020202;
                }
            }
            &:hover{
                background: #020202;
                color: #FFFFFF;
                text-decoration: none;
            }
        }
    }
    
    input[type=text]:focus+.toggleSelectorIndicator+.selector{
        display: block;
    }
}
.checkbox{
    display:inline-block;
	width:22px;
	height:22px;
    user-select: none;
    margin-right: 16px;
    vertical-align: middle;
    //background-image: url('../images/checkbox.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 2px;
    &.checked{
        //background-image: url('../images/checkbox-checked.svg');
    }
}
